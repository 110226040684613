<script>
import { Button, Tabs, Input } from 'ant-design-vue'
import { mapActions } from 'vuex'
import { isShareCoolAndRefreshing,getTitle } from '../../utils/business'
import {basePath} from '@/config/router.config'
export default {
  data() {
    return {
      username: '',
      password: '',
      phoneNumber: '',
      getCodeMsg: '发送验证码',
      countdown: 60,
      code: '',
      showEye: true,
      formType: 'form',
    }
  },
  methods: {
    ...mapActions(['Login', 'Logout', 'GetCode', 'LoginByCode', 'GetCodeFind', 'UpdatePwd']),
    renderImg() {
      return (
        <div class="login-img">
          <div class="login-img-box-one"></div>
          <div class="login-img-box-two">
            {this.formType !== 'forgot' ? this.renderTab() : this.renderForgotPassword()}
          </div>
        </div>
      )
    },
    renderTab() {
      const data = [
        {
          name: '账号登录',
          view: this.renderLoginUserName,
        },
        {
          name: '验证码登录',
          view: this.renderLoginPhone,
        },
      ]
      return (
        <div class="login-form tabs-box">
          <Tabs>
            {data.map((item) => {
              return (
                <Tabs.TabPane tab={item.name} key={item.name}>
                  {item.view()}
                </Tabs.TabPane>
              )
            })}
          </Tabs>
        </div>
      )
    },
    renderLoginUserName() {
      return (
        <div class="login-username">
          <div class="inp-box">
            <img src={require('./asset/icon1.png')} />
            <Input placeholder={'请输入登录账号'} v-model={this.username} class="ing-cla" />
          </div>
          <div class="inp-box">
            <img src={require('./asset/icon2.png')} />
            <Input
              placeholder={'请输入登录密码'}
              type={this.showEye ? 'password' : 'text'}
              v-model={this.password}
              onPressEnter={this.onLogin}
              class="ing-cla"
            />
          </div>
          <div class="btn-box1">
            <div></div>
            <div onClick={this.changeFormType}>忘记密码？</div>
          </div>
          <div class="btn-box2" onClick={this.onLogin}>
            登录
          </div>
        </div>
      )
    },
    renderLoginPhone() {
      return (
        <div class="login-phone">
          <div class="inp-box">
            <Input placeholder={'手机号码'} v-model={this.phoneNumber} class="ing-cla" />
            <a onClick={this.getCode}>{this.getCodeMsg}</a>
          </div>
          <div class="inp-box">
            <Input class="ing-cla" placeholder={'验证码'} v-model={this.code} />
          </div>
          <div class="btn-box1">
            <div></div>
            <div onClick={this.changeFormType}>忘记密码？</div>
          </div>
          <div class="btn-box2" onClick={this.onLoginByCode}>
            登录
          </div>
        </div>
      )
    },

    renderForgotPassword() {
      return (
        <div class="login-forgot-password">
          <p class="title">设置新密码</p>
          <div class="inp-box">
            <Input placeholder={'手机号码'} v-model={this.phoneNumber} class="ing-cla" />
            <a onClick={this.getCodeFind}>{this.getCodeMsg}</a>
          </div>
          <div class="inp-box">
            <Input placeholder={'验证码'} class="ing-cla" v-model={this.code} />
          </div>
          <div class="inp-box">
            <Input
              placeholder={'密码'}
              class="ing-cla"
              type={this.showEye ? 'password' : 'text'}
              v-model={this.password}
            />
            {this.showEye ? (
              <img src={require('./asset/eye.png')} onClick={() => (this.showEye = !this.showEye)} />
            ) : (
              <img src={require('./asset/eye_no.png')} onClick={() => (this.showEye = !this.showEye)} />
            )}
          </div>
          <div class="btn-box2" onClick={this.onNext}>
            修改密码
          </div>
        </div>
      )
    },
    loginSuccess(userData) {
      const { user_info } = userData
      if(user_info.userId){
        user_info.id = user_info.userId
      }
      localStorage.setItem('user_info', JSON.stringify(user_info))
      if (this.$route.query.redirect) {
        window.location.href = this.$route.query.redirect + '?token=' + localStorage.getItem('CX-Access-Token')
      } else {
        if (process.env.NODE_ENV === 'development') {
          window.location.href = basePath+'/index'
        } else {
          window.location.href = location.origin +basePath + '/bi?token=' + localStorage.getItem('CX-Access-Token')
        }
      }
    },
    onLogin() {
      if (this.username === '' || this.password === '') {
        this.$message.error('请输入账号/密码')
        return
      }
      this.Login({
        username: this.username,
        password: this.password,
      })
        .then((result) => {
          
          this.loginSuccess(result)
        })
        .catch(() => this.$message.error('账号或密码错误'))
        .finally(() => {
          // state.loginBtn = false
        })
    },
    // 重置
    resetValue() {
      this.getCodeMsg = '发送验证码'
      this.getDisabled = false
      this.countdown = 60
      clearInterval(this.timeOut)
      this.timeOut = ''
      this.username = ''
      this.password = ''
      this.phoneNumber = ''
      this.code = ''
      this.formType = 'form'
    },
    // 找回手机确认
    onNext() {
      if (this.phoneNumber === '' || this.code === '') {
        this.$message.error('请输入手机号/验证码')
        return
      }
      this.UpdatePwd({
        code: this.code,
        password: this.password,
        phone: this.phoneNumber,
      }).then((e) => {
        if (e.code === 200) {
          this.resetValue()
          this.isShow = 'login-form-item'
          this.$message.success('修改密码成功')
          this.loginWayFindPass = !this.loginWayFindPass
          this.loginWay = !this.loginWay
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    // 验证码登录按钮
    onLoginByCode() {
      if (this.phoneNumber === '' || this.code === '') {
        this.$message.error('请输入手机号/验证码')
        return
      }
      this.LoginByCode({
        phoneNumber: this.phoneNumber,
        code: this.code,
      }).then((e) => {
        if (e.access_token) {
          this.loginSuccess(e)
        } else {
          this.$message.error(e.msg)
        }
      })
    },

    getCodeFind() {
      if (!this.phoneNumber) {
        this.$message.error('请输入手机号码')
        return
      }
      this.GetCodeFind({
        phoneNumber: this.phoneNumber,
      }).then((e) => {
        if (e.code === 200) {
          this.$message.success('验证码已发送')
          this.getDisabled = true
          this.timeOut = setInterval(this.setCountdown, 1000)
          this.code = e.data
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    getCode() {
      if (!this.phoneNumber) {
        this.$message.error('请输入手机号码')
        return
      }
      this.GetCode({
        phoneNumber: this.phoneNumber,
      }).then((e) => {
        if (e.code === 200) {
          this.$message.success('验证码已发送')
          this.getDisabled = true
          this.timeOut = setInterval(this.setCountdown, 1000)
          this.code = e.data
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    setCountdown() {
      if (this.countdown === 0) {
        this.getCodeNow()
      } else {
        this.getCodeMsg = this.countdown + 's'
        this.getDisabled = true
        this.countdown--
      }
    },
    getCodeNow() {
      this.getCodeMsg = '发送验证码'
      this.getDisabled = false
      this.countdown = 60
      clearInterval(this.timeOut)
    },
    changeFormType() {
      this.formType = 'forgot'
      clearInterval(this.timeOut)
      this.countdown = 60
      this.getCodeMsg = '发送验证码'
    },
  },
  render() {
    return (
      <div class="login-box">
        <div class="login-main">
          <div class="login-head">
            <img src={isShareCoolAndRefreshing ?require('./asset/title-img.png'): require('./asset/title-img2.png')} />
            <span>{getTitle()}让农产品鲜爽，共同富裕让农民心情爽</span>
          </div>
          <div class="login-body">{this.renderImg()}</div>
          <div class="login-foot">
            <span>Copyright @ 2021-2022 浙江中兴慧农信息科技有限公司 Reserved</span>
            <span>服务热线：0574 -28840325</span>
          </div>
        </div>
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.login-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
}

.login-main {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url('./asset/bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 114px 282px 49px;
  box-sizing: border-box;
  background-position: 0% 30%;
  position: relative;
}

.login-head {
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 30px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #555555;
  }
}

.login-foot {
  width: 100%;
  font-size: 10px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.login-body {
  width: 100%;
  height: 545px;
  //   position: absolute;

  .login-img {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .login-img-box-one {
      width: 50%;
    }

    .login-img-box-two {
      width: 533px;
      height: 545px;
      background-image: url('./asset/bg2.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 88px 99px 116px 111px;
      box-sizing: border-box;

      .tabs-box {
        /deep/.ant-tabs-bar {
          border: 0;
        }

        /deep/.ant-tabs-ink-bar {
          width: 40px !important;
          height: 4px;
          background: #0088ff;
          border-radius: 2px;
          left: 17%;
        }

        /deep/.ant-tabs-tab {
          font-size: 22px;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          color: #707980;
          width: 142px;
          text-align: center;

          &.ant-tabs-tab-active {
            font-size: 22px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }
  }
}

.login-username {
  width: 100%;
  height: auto;
  margin-top: 45px;

  .btn-box1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #a9a9a9;
  }

  .btn-box2 {
    margin-top: 45px;
    width: 323px;
    height: 55px;
    background: #2665fa;
    border-radius: 8px;
    text-align: center;
    line-height: 55px;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
  }

  .inp-box {
    position: relative;
    margin-bottom: 18px;

    img {
      position: absolute;
      left: 18px;
      top: 16px;
      width: 24px;
      height: 24px;
      z-index: 99;
    }

    .ing-cla {
      width: 323px;
      height: 55px;
      background: #f2f4f9;
      border-radius: 8px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      padding-left: 53px;
      box-sizing: border-box;

      &::placeholder {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #a9a9a9;
      }
    }
  }
}

.login-phone {
  width: 100%;
  height: auto;
  margin-top: 45px;

  .btn-box1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #a9a9a9;
  }

  .btn-box2 {
    margin-top: 45px;
    width: 323px;
    height: 55px;
    background: #2665fa;
    border-radius: 8px;
    text-align: center;
    line-height: 55px;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
  }

  .inp-box {
    position: relative;
    margin-bottom: 18px;

    a {
      position: absolute;
      right: 15px;
      top: 17px;
    }

    .ing-cla {
      width: 323px;
      height: 55px;
      background: #f2f4f9;
      border-radius: 8px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      padding-right: 100px;
      box-sizing: border-box;

      &::placeholder {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #a9a9a9;
      }
    }
  }
}

.login-forgot-password {
  width: 100%;
  height: auto;
  margin-top: 15px;

  .title {
    text-align: center;
    font-size: 22px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #333333;
  }

  .btn-box1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #a9a9a9;
  }

  .btn-box2 {
    margin-top: 45px;
    width: 323px;
    height: 55px;
    background: #2665fa;
    border-radius: 8px;
    text-align: center;
    line-height: 55px;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
  }

  .inp-box {
    position: relative;
    margin-bottom: 18px;

    a {
      position: absolute;
      right: 15px;
      top: 17px;
    }

    img {
      position: absolute;
      right: 15px;
      top: 12px;
    }

    .ing-cla {
      width: 323px;
      height: 55px;
      background: #f2f4f9;
      border-radius: 8px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      padding-right: 100px;
      box-sizing: border-box;

      &::placeholder {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #a9a9a9;
      }
    }
  }
}
</style>
